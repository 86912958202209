import { AnonymousSpin } from "@/types/AnonymousSpin";
import { ThemePreference} from "@/types/Theme";
import {AccountLevel, ApiError, BrandMap, GetUserResponse, HandoffOptions} from "@devour/client";
import {overwolfGameInfo} from "@/hooks/useOverwolfInterop";

export interface IMetaStore {
    loadingIncrement?: number;
    errors?: Array<ApiError>;
    menuOrderError: { restaurantId?: string; errorMessage?: string; };
    devourIqMenuOrderError: { restaurantId?: string; errorMessage?: string; };
    sidebarOpen: boolean;
    globalContactModalVisible: boolean;
    currentUser: GetUserResponse;
    activeModals: number; // Helps manage nested modal render order.
    themePreference: ThemePreference;
    menuOrders: { [key: string]: string };
    menuOrdersRefresh: number;
    dismissedWalletConnectPrompt: boolean;
    lastSearchedPlaceId: string;
    lastMenuOrderId: string;
    lastRestaurantId: string;
    lastBrandMap: BrandMap;
    showCartDrawer: boolean;
    accountLevel: AccountLevel;
    handoff: HandoffOptions;
    lastDeletionEmailSent?: number;
    sidebarModalActive: boolean; // handle modals toggled by sidebar to control sticky settings on categories
    activeGameModal: string; // handle active game modal
    dpayPriceExpiryTime: number;
    forceRefreshTransactions: boolean; // handle DPAY slippage errors, forcing refresh
    isIndustryDismissed: boolean; // if toggled then the industry promo modal won't show again
    isIndustryDismissedForSession: boolean; // same as above, but handles a session long dismissal
    isBottomNavHidden: boolean; // toggle bottom nav on tablet/mobile view
    isMagicWalletsModalActive: boolean;
    isGoFrensTutorialBannerDismissed: boolean; // if toggled, the 'How does GoFriends work?' won't show again
    isShowStickyFooter: boolean; // toggles the sticky footer nav on mobile and tablet, toggling is used in the restaurants pages
    isChatOpen: boolean;
    isLoginOpen: boolean;
    anonymousSpin?: AnonymousSpin;
    isOnOverwolf: boolean;
    overwolfGameInfo: overwolfGameInfo | null;
    isMenuOrderUpdating: boolean;
    isFuelUpModalDismissed: boolean;
}

export default {
    loadingIncrement: 0,
    errors: [],
    menuOrderError: {},
    devourIqMenuOrderError: {},
    sidebarOpen: false,
    globalContactModalVisible: false,
    currentUser: undefined,
    activeModals: 0,
    themePreference: ThemePreference.DARK,
    menuOrders: undefined,
    menuOrdersRefresh: 0,
    dismissedWalletConnectPrompt: false,
    lastMenuOrderId: undefined,
    lastRestaurantId: undefined,
    lastBrandMap: undefined,
    lastSearchedPlaceId: undefined,
    showCartDrawer: false,
    accountLevel: null,
    handoff: HandoffOptions.DELIVERY,
    sidebarModalActive: false,
    activeGameModal: "",
    dpayPriceExpiryTime: undefined,
    forceRefreshTransactions: false,
    isIndustryDismissed: false,
    isIndustryDismissedForSession: false,
    isBottomNavHidden: false,
    isMagicWalletsModalActive: false,
    isGoFrensTutorialBannerDismissed: false,
    isShowStickyFooter: false,
    isChatOpen: false,
    isLoginOpen: false,
    isOnOverwolf: false,
    overwolfGameInfo: null,
    anonymousSpin: undefined,
    isMenuOrderUpdating: false,
    isFuelUpModalDismissed: false,
} as IMetaStore;
