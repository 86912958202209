import InitialMetaStore, {IMetaStore} from "./InitialMetaStore";
import {IAction} from "../IAction";
import {cloneDeep, uniqBy} from "lodash";
import {AuthAction} from "../auth/authReducer";

export enum MetaAction {
    LOADING = "LOADING",
    ADD_ERROR = "ADD_ERROR",
    REMOVE_ERROR = "REMOVE_ERROR",
    ADD_MENU_ORDER_ERROR = "ADD_MENU_ORDER_ERROR",
    REMOVE_MENU_ORDER_ERROR = "REMOVE_MENU_ORDER_ERROR",
    ADD_DEVOUR_IQ_MENU_ORDER_ERROR = "ADD_DEVOUR_IQ_MENU_ORDER_ERROR",
    REMOVE_DEVOUR_IQ_MENU_ORDER_ERROR = "REMOVE_DEVOUR_IQ_MENU_ORDER_ERROR",
    SIDEBAR = "SIDEBAR",
    GLOBAL_CONTACT_MODAL = "GLOBAL_CONTACT_MODAL",
    UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER",
    MODAL_COUNT = "MODAL_COUNT",
    SET_PREFERRED_THEME = "SET_PREFERRED_THEME",
    RESET_MODAL_COUNT = "RESET_MODAL_COUNT",
    ADD_MENU_ORDER = "ADD_MENU_ORDER",
    REMOVE_MENU_ORDER = "REMOVE_MENU_ORDER",
    REFRESH_MENU_ORDER_CART = "REFRESH_MENU_ORDER_CART",
    DISMISS_WALLET_CONNECT_PROMPT = "DISMISS_WALLET_CONNECT_PROMPT",
    UPDATE_LAST_SEARCHED_PLACE_ID = "UPDATE_LAST_SEARCHED_PLACE_ID",
    UPDATE_LAST_MENU_ORDER_ID = "UPDATE_LAST_MENU_ORDER_ID",
    UPDATE_LAST_RESTAURANT_ID = "UPDATE_LAST_RESTAURANT_ID",
    UPDATE_LAST_BRAND_MAP = "UPDATE_LAST_BRAND_MAP",
    TOGGLE_CART_DRAWER = "TOGGLE_CART_DRAWER",
    UPDATE_ACCOUNT_LEVEL = "UPDATE_ACCOUNT_LEVEL",
    TOGGLE_ORDER_HANDOFF = "TOGGLE_ORDER_HANDOFF",
    UPDATE_LAST_DELETION_EMAIL_CALL = "UPDATE_LAST_DELETION_EMAIL_CALL",
    UPDATE_SIDEBAR_MODAL_ACTIVE = "UPDATE_SIDEBAR_MODAL_ACTIVE",
    UPDATE_ACTIVE_GAME_MODAL = "UPDATE_ACTIVE_GAME_MODAL",
    RESET_ACTIVE_GAME_MODAL = "RESET_ACTIVE_GAME_MODAL",
    UPDATE_DPAY_PRICE_EXPIRY_TIME = "UPDATE_DPAY_PRICE_EXPIRY_TIME",
    REMOVE_DPAY_PRICE_EXPIRY_TIME = "REMOVE_DPAY_PRICE_EXPIRY_TIME",
    UPDATE_FORCE_REFRESH_TRANSACTIONS = "UPDATE_FORCE_REFRESH_TRANSACTIONS",
    UPDATE_IS_INDUSTRY_DISMISSED = "UPDATE_IS_INDUSTRY_DISMISSED",
    UPDATE_IS_INDUSTRY_DISMISSED_FOR_SESSION = "UPDATE_IS_INDUSTRY_DISMISSED_FOR_SESSION",
    TOGGLE_INDUSTRY_PASS_MODAL = "TOGGLE_INDUSTRY_PASS_MODAL",
    TOGGLE_HIDE_BOTTOM_NAV = "TOGGLE_HIDE_BOTTOM_NAV",
    TOGGLE_MAGIC_WALLETS_MODAL = "TOGGLE_MAGIC_WALLETS_MODAL",
    UPDATE_IS_GOFRENS_TUTORIAL_BANNER_DISMISSED = "UPDATE_IS_GOFRENS_TUTORIAL_BANNER_DISMISSED",
    TOGGLE_STICKY_FOOTER = "TOGGLE_STICKY_FOOTER",
    TOGGLE_CHAT_OPEN = "TOGGLE_CHAT_OPEN",
    TOGGLE_LOGIN_OPEN = "TOGGLE_LOGIN_OPEN",
    UPDATE_ANONYMOUS_SPIN = "UPDATE_ANONYMOUS_SPIN",
    IS_ON_OVERWOLF = "IS_ON_OVERWOLF",
    OVERWOLF_GAME_INFO = "OVERWOLF_GAME_INFO",
    IS_MENU_ORDER_UPDATING = "IS_MENU_ORDER_UPDATING",
    UPDATE_IS_FUEL_UP_MODAL_DISMISSED = "UPDATE_IS_FUEL_UP_MODAL_DISMISSED",
}

export default function metaReducer(store: IMetaStore = InitialMetaStore, a: IAction<MetaAction | AuthAction, any>): IMetaStore {

    const n: IMetaStore = cloneDeep(store);

    switch (a.type) {
        case AuthAction.LOGOUT:
            delete n.menuOrders;
            delete n.currentUser;
            delete n.lastSearchedPlaceId;
            delete n.lastMenuOrderId;
            delete n.lastBrandMap;
            delete n.accountLevel;
            delete n.lastDeletionEmailSent;
            break;

        case MetaAction.LOADING:
            if (n.loadingIncrement + a.payload >= 0) {
                n.loadingIncrement += a.payload;
            } else {
                n.loadingIncrement = 0;
            }
            break;
        case MetaAction.ADD_ERROR:
            n.errors.push(a.payload);

            // remove any duplicate errors
            n.errors = uniqBy(n.errors, "message");
            // console.log("unqiue", uniqBy(n.errors, "message"), n.errors)

            break;
        case MetaAction.REMOVE_ERROR:
            n.errors.splice(a.payload, 1);
            break;
        case MetaAction.ADD_MENU_ORDER_ERROR:
            n.menuOrderError = a.payload;
            break;
        case MetaAction.REMOVE_MENU_ORDER_ERROR:
            n.menuOrderError = {};
            break;
        case MetaAction.ADD_DEVOUR_IQ_MENU_ORDER_ERROR:
            n.devourIqMenuOrderError = a.payload;
            break;
        case MetaAction.REMOVE_DEVOUR_IQ_MENU_ORDER_ERROR:
            n.devourIqMenuOrderError = {};
            break;
        case MetaAction.SIDEBAR:
            n.sidebarOpen = a.payload;
            break;
        case MetaAction.GLOBAL_CONTACT_MODAL:
            n.globalContactModalVisible = a.payload;
            break;
        case MetaAction.UPDATE_CURRENT_USER:
            n.currentUser = a.payload;
            break;
        case MetaAction.MODAL_COUNT:
            if (n.activeModals + a.payload >= 0) {
                n.activeModals += a.payload;
            } else {
                n.activeModals = 0;
            }
            break;
        case MetaAction.SET_PREFERRED_THEME:
            n.themePreference = a.payload;
            break;
        case MetaAction.RESET_MODAL_COUNT:
            n.activeModals = 0;
            break;
        case MetaAction.ADD_MENU_ORDER:
            if (!n.menuOrders) {
                n.menuOrders = {};
            }
            n.menuOrders[a.payload.restaurantId] = a.payload.menuOrderId;
            break;
        case MetaAction.REMOVE_MENU_ORDER:
            if (n?.menuOrders[a.payload]) {
                delete n.menuOrders[a.payload];
            }
            break;
        case MetaAction.REFRESH_MENU_ORDER_CART:
            n.menuOrdersRefresh = n.menuOrdersRefresh + 1;
            break;
        case MetaAction.DISMISS_WALLET_CONNECT_PROMPT:
            n.dismissedWalletConnectPrompt = true;
            break;
        case MetaAction.UPDATE_LAST_SEARCHED_PLACE_ID:
            n.lastSearchedPlaceId = a.payload;
            break;
        case MetaAction.UPDATE_LAST_MENU_ORDER_ID:
            n.lastMenuOrderId = a.payload;
            break;
        case MetaAction.UPDATE_LAST_RESTAURANT_ID:
            n.lastRestaurantId = a.payload;
            break;
        case MetaAction.UPDATE_LAST_BRAND_MAP:
            n.lastBrandMap = a.payload;
            break;
        case MetaAction.TOGGLE_CART_DRAWER:
            n.showCartDrawer = a.payload;
            break;
        case MetaAction.UPDATE_ACCOUNT_LEVEL:
            n.accountLevel = a.payload;
            break;
        case MetaAction.TOGGLE_ORDER_HANDOFF:
            n.handoff = a.payload;
            break;
        case MetaAction.UPDATE_LAST_DELETION_EMAIL_CALL:
            n.lastDeletionEmailSent = a.payload;
            break;
        case MetaAction.UPDATE_SIDEBAR_MODAL_ACTIVE:
            n.sidebarModalActive = a.payload;
            break;
        case MetaAction.UPDATE_ACTIVE_GAME_MODAL:
            n.activeGameModal = a.payload;
            break;
        case MetaAction.RESET_ACTIVE_GAME_MODAL:
            n.activeGameModal = "";
            break;
        case MetaAction.UPDATE_DPAY_PRICE_EXPIRY_TIME:
            n.dpayPriceExpiryTime = a.payload;
            break;
        case MetaAction.REMOVE_DPAY_PRICE_EXPIRY_TIME:
            delete n.dpayPriceExpiryTime;
            break;
        case MetaAction.UPDATE_FORCE_REFRESH_TRANSACTIONS:
            n.forceRefreshTransactions = a.payload;
            break;
        case MetaAction.UPDATE_IS_INDUSTRY_DISMISSED:
            n.isIndustryDismissed = a.payload;
            break;
        case MetaAction.UPDATE_IS_INDUSTRY_DISMISSED_FOR_SESSION:
            n.isIndustryDismissedForSession = a.payload;
            break;
        case MetaAction.TOGGLE_HIDE_BOTTOM_NAV:
            n.isBottomNavHidden = a.payload;
            break;
        case MetaAction.TOGGLE_MAGIC_WALLETS_MODAL:
            n.isMagicWalletsModalActive = a.payload;
            break;
        case MetaAction.UPDATE_IS_GOFRENS_TUTORIAL_BANNER_DISMISSED:
            n.isGoFrensTutorialBannerDismissed = a.payload;
            break;
        case MetaAction.TOGGLE_STICKY_FOOTER:
            n.isShowStickyFooter = a.payload;
            break;
        case MetaAction.TOGGLE_CHAT_OPEN:
            n.isChatOpen = a.payload;
            break;
        case MetaAction.TOGGLE_LOGIN_OPEN:
            n.isLoginOpen = a.payload;
            break;
        case MetaAction.UPDATE_ANONYMOUS_SPIN:
            n.anonymousSpin = a.payload;
            break;
        case MetaAction.IS_ON_OVERWOLF:
            n.isOnOverwolf = a.payload;
            break;
        case MetaAction.OVERWOLF_GAME_INFO:
            n.overwolfGameInfo = a.payload;
            break;
        case MetaAction.IS_MENU_ORDER_UPDATING:
            n.isMenuOrderUpdating = a.payload;
            break;
        case MetaAction.UPDATE_IS_FUEL_UP_MODAL_DISMISSED:
            n.isFuelUpModalDismissed = a.payload;
            break;
        default:
            break;
    }

    return n;
}
